import React, { FC, useEffect, useState } from 'react';
import { c } from '../../../../lib/util';
import Logo from '../../../Logo';
import ShadowBackground from '../../../ShadowBackground';
import Slide, { SlidePosition, SlideProps } from '../../Slide';
import styles from './styles.module.scss';
import dokter1 from './dokter1.jpeg';
import dokter3 from './dokter3.jpeg';
import dokter4 from './dokter4.jpeg';
import dokter5 from './dokter5.jpeg';

const Processors: FC<SlideProps> = ({ position }): JSX.Element => {
    const [ delayedPosition, setDelayedPosition ] = useState<SlidePosition>();

    useEffect(() => {
        setTimeout(() => setDelayedPosition(position), position === 'center' ? 500 : 1);
    }, [position]);

    return (
        <Slide position={position}>
            <div className={c([styles.processors, delayedPosition === 'center' && styles.isActive])}>
                <ShadowBackground bottom random={7}>
                    <Slide.Body>
                        <Logo
                            className={styles.logo}
                            // white
                        />
                        <Slide.Title>Onze zorgspecialisten</Slide.Title>
                        <p>Altijd in contact met de juiste zorgspecialisten.</p>

                        <div className="row" style={{ marginTop: 48 }}>
                            <div className="col-3">
                                <div className={styles.profile}>
                                    <div
                                        className={styles.avatar}
                                        style={{ backgroundImage: `url(${dokter4})` }}
                                    />
                                    <div className={styles.content}>
                                        <h3>Stafarts</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className={styles.profile}>
                                    <div
                                        className={styles.avatar}
                                        style={{ backgroundImage: `url(${dokter5})` }}
                                    />
                                    <div className={styles.content}>
                                        <h3>Bedrijfsarts</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className={styles.profile}>
                                    <div
                                        className={styles.avatar}
                                        style={{ backgroundImage: `url(${dokter1})` }}
                                    />
                                    <div className={styles.content}>
                                        <h3>Verpleegkundige</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className={styles.profile}>
                                    <div
                                        className={styles.avatar}
                                        style={{ backgroundImage: `url(${dokter3})` }}
                                    />
                                    <div className={styles.content}>
                                        <h3>Casemanager</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slide.Body>
                </ShadowBackground>
            </div>
        </Slide>
    );
}

export default Processors;
