import React, { FC, useEffect, useState } from 'react';
import { c } from '../../../../lib/util';
import Logo from '../../../Logo';
import ShadowBackground from '../../../ShadowBackground';
import Slide, { SlidePosition, SlideProps } from '../../Slide';
import styles from './styles.module.scss';

const MarketAdoption: FC<SlideProps> = ({ position }): JSX.Element => {
    const [ delayedPosition, setDelayedPosition ] = useState<SlidePosition>();

    useEffect(() => {
        setTimeout(() => setDelayedPosition(position), position === 'center' ? 500 : 1);
    }, [position]);

    return (
        <Slide position={position}>
            <div className={c([styles.marketAdoption, delayedPosition === 'center' && styles.isActive])}>
                <ShadowBackground random={8} bottom>
                    <Slide.Body>
                        <Logo
                            className={styles.logo}
                        />
                        <Slide.Title>Market Adoption</Slide.Title>

                        <div className="row align-items-center">
                            <div className="col-4">
                                <div className={styles.fase}>
                                    <div className={styles.title}>
                                        Development
                                    </div>
                                    <div className={c([styles.block, styles.b1])}>
                                        Versie 1
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className={styles.fase}>
                                    <div className={styles.title}>
                                        Development &amp; pilot
                                    </div>
                                    <div className={c([styles.block, styles.b2])}>
                                        Pilot klant 1
                                    </div>
                                    <div className={c([styles.block, styles.b3])}>
                                        Pilot klant 2
                                    </div>
                                    <div className={c([styles.block, styles.b4])}>
                                        Pilot klant 3
                                    </div>
                                    <div className={styles.footer}>
                                        1 december 2022
                                        <small>&nbsp;</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className={c([styles.fase, styles.last])}>
                                    <div className={styles.title}>
                                        Innovatie, marketing &amp; sales
                                    </div>
                                    <div className={c([styles.block, styles.b5])}>
                                        Klant 1
                                    </div>
                                    <div className={c([styles.block, styles.b6])}>
                                        Klant 2
                                    </div>
                                    <div className={c([styles.block, styles.b7])}>
                                        Klant 3
                                    </div>
                                    <div className={c([styles.block, styles.b8])}>
                                        Klant 4
                                    </div>
                                    <div className={c([styles.block, styles.b9])}>
                                        Klant 5
                                    </div>
                                    <div className={styles.footer}>
                                        1 maart 2023
                                        <small>Lancering</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slide.Body>
                </ShadowBackground>
            </div>
        </Slide>
    );
}

export default MarketAdoption;
