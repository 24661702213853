import React, { FC, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { c } from '../../../../lib/util';
import Slide, { SlidePosition, SlideProps } from '../../Slide';
import styles from './styles.module.scss';
import pic1 from './1.jpg';
import pic2 from './2.jpg';
import pic3 from './3.jpg';
import pic4 from './4.jpg';
import pic5 from './5.jpg';
import pic6 from './6.jpg';
import pic7 from './7.jpg';
import pic8 from './8.jpg';
import pic9 from './9.jpg';
import pic10 from './10.jpg';
import pic11 from './11.jpg';
import pic12 from './12.jpg';
import pic13 from './13.jpg';
import pic14 from './14.jpg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Portfolio: FC<SlideProps> = ({ position }): JSX.Element => {
    const [ current, setCurrent ] = useState<number>(0);
    const [ delayedPosition, setDelayedPosition ] = useState<SlidePosition>();

    useEffect(() => {
        const handleSlide = ({ detail }: any): void => {
            if (detail.type === 'next') {
                setCurrent((c) => c >= 13 ? 1 : (c + 1));
            } else if (detail.type === 'prev') {
                setCurrent((c) => c > 0 ? (c - 1) : 13);
            }
        }

        window.addEventListener('arrow', handleSlide, true);
        return () => window.removeEventListener('arrow', handleSlide, true);
    }, []);

    useEffect(() => {
        setTimeout(() => setDelayedPosition(position), position === 'center' ? 500 : 1);
    }, [position]);

    return (
        <Slide position={position}>
            <div className={c([styles.portfolio, delayedPosition === 'center' && styles.isActive])}>
                <Slide.Body style={{ margin: 0, padding: 0 }}>
                    <Carousel
                        className={styles.pics}
                        infiniteLoop
                        showArrows={false}
                        showStatus={false}
                        showThumbs={false}
                        selectedItem={current}
                    >
                        <img src={pic1} alt="" style={{ width: '100%' }} />
                        <img src={pic2} alt="" style={{ width: '100%' }} />
                        <img src={pic3} alt="" style={{ width: '100%' }} />
                        <img src={pic4} alt="" style={{ width: '100%' }} />
                        <img src={pic5} alt="" style={{ width: '100%' }} />
                        <img src={pic6} alt="" style={{ width: '100%' }} />
                        <img src={pic7} alt="" style={{ width: '100%' }} />
                        <img src={pic8} alt="" style={{ width: '100%' }} />
                        <img src={pic9} alt="" style={{ width: '100%' }} />
                        <img src={pic10} alt="" style={{ width: '100%' }} />
                        <img src={pic11} alt="" style={{ width: '100%' }} />
                        <img src={pic12} alt="" style={{ width: '100%' }} />
                        <img src={pic13} alt="" style={{ width: '100%' }} />
                        <img src={pic14} alt="" style={{ width: '100%' }} />
                    </Carousel>
                </Slide.Body>
            </div>
        </Slide>
    );
}

export default Portfolio;
