import React, { FC, useEffect, useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { c, countUp } from '../../../../lib/util';
import Graph from './ziekteverzuim.png';
import Logo from '../../../Logo';
import ShadowBackground from '../../../ShadowBackground';
import Slide, { SlidePosition, SlideProps } from '../../Slide';
import styles from './styles.module.scss';

const MarketValidation: FC<SlideProps> = ({ position }): JSX.Element => {
    const [ delayedPosition, setDelayedPosition ] = useState<SlidePosition>();

    useEffect(() => {
        setTimeout(() => setDelayedPosition(position), position === 'center' ? 500 : 1);
    }, [position]);

    useEffect(() => {
        countUp(document.querySelector('#count-1 > div > span') as HTMLSpanElement, 20000, 2500)();
        countUp(document.querySelector('#count-2 > div > span') as HTMLSpanElement, 670000, 3500)();
    }, [delayedPosition]);

    return (
        <Slide position={position}>
            <div className={c([styles.marketValidation, delayedPosition === 'center' && styles.isActive])}>
                <ShadowBackground random={4} bottom noImage>
                    <Slide.Body style={{ height: '100%' }}>
                        <Logo
                            className={styles.logo}
                        />
                        <Slide.Title>Market validation</Slide.Title>

                        <div className="row align-items-center">
                            <div className="col-md-7">
                                <Zoom>
                                    <img src={Graph} alt="" style={{ maxHeight: 440 }} />
                                </Zoom>
                            </div>
                            <div className="col-md-5">
                                <p>
                                    Het ziekteverzuim onder werknemers van bedrijven en overheid is in het eerste kwartaal van 2022 gestegen tot 6,3 procent, het hoogste percentage dat het CBS ooit heeft gemeten. Het betekent dat van de duizend te werken dagen 63 werden verzuimd wegens ziekte. In dezelfde periode van 2021 was het verzuim 4,8 procent.

                                    <span className={styles.source}>
                                        Bron: <a
                                            href="https://www.cbs.nl/nl-nl/visualisaties/dashboard-arbeidsmarkt/werkenden/ziekteverzuim"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            https://www.cbs.nl/nl-nl/visualisaties/dashboard-arbeidsmarkt/werkenden/ziekteverzuim
                                        </a>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </Slide.Body>
                </ShadowBackground>
            </div>
        </Slide>
    );
}

export default MarketValidation;
