import React, { FC, useEffect, useState } from 'react';
import { c } from '../../../../lib/util';
import Logo from '../../../Logo';
import ShadowBackground from '../../../ShadowBackground';
import Slide, { SlidePosition, SlideProps } from '../../Slide';
import styles from './styles.module.scss';

const Cover: FC<SlideProps> = ({ position }): JSX.Element => {
    const [ delayedPosition, setDelayedPosition ] = useState<SlidePosition>();

    useEffect(() => {
        setTimeout(() => setDelayedPosition(position), position === 'center' ? 500 : 1);
    }, [position]);

    return (
        <Slide position={position}>
            <div className={c([styles.cover, delayedPosition === 'center' && styles.isActive])}>
                <ShadowBackground
                    shadow={false}
                    random={1}
                >
                    <div style={{ height: '100%' }} />
                    <div className={styles.titleContainer}>
                        <Logo />
                        <div className={styles.title}>
                            <h1>Pitch Deck</h1>
                            <p>Een laagdrempelige en opschaalbare "Digital First" bedrijfsgezondheidszorg oplossing</p>
                        </div>
                    </div>
                </ShadowBackground>
            </div>
        </Slide>
    );
}

export default Cover;
