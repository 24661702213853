import React, { FC } from 'react';
import styles from './styles.module.scss';

interface TeamMemberProps {
    avatar: string,
    content?: string | JSX.Element,
    title: string,
}

const TeamMember: FC<TeamMemberProps> = ({ avatar, content, title }): JSX.Element => {
    return (
        <div className={styles.container}>
            <div className={styles.avatar}>
                <img src={avatar} alt="" />
            </div>
            <div className={styles.content}>
                <h3>{title}</h3>
                {content && <p>{content}</p>}
            </div>
        </div>
    );
}

export default TeamMember;
