import React, { FC, useState } from 'react';
import Arrow from './Arrow';
import BusinessModel from './slides/BusinessModel';
import Competition from './slides/Competition';
import Cover from './slides/Cover';
import Financial from './slides/Financial';
import MarketAdoption from './slides/MarketAdoption';
import MarketSize from './slides/MarketSize';
import MarketValidation from './slides/MarketValidation';
import Problem from './slides/Problem';
import ProductTitle from './slides/ProductTitle';
import Product from './slides/Product';
import Solution from './slides/Solution';
import Team from './slides/Team';
import Portfolio from './slides/Portfolio';
import ProductAutomated from './slides/ProductAutomated';
import ProductCapacity from './slides/ProductCapacity'
import ProductData from './slides/ProductData';
import Photo from './slides/Photo';
import Processors from './slides/Processors';
import styles from './styles.module.scss';

const Carousel: FC = (): JSX.Element => {
    const [ currentSlide, setCurrentSlide ] = useState<number>(0);

    return (<>
        <div className={styles.info}>
            <div>
                Bekijk deze presentatie op een groter scherm voor een optimale ervaring.
            </div>
        </div>
        <div
            className={styles.carouselContainer}
        >
            <Arrow
                currentSlide={currentSlide}
                goto={setCurrentSlide}
                type="prev"
            />
            <div
                className={styles.carousel}
            >
                <Cover
                    position={currentSlide === 0 ? 'center' : (currentSlide > 0 ? 'left' : 'right')}
                />
                <Problem
                    position={currentSlide === 1 ? 'center' : (currentSlide > 1 ? 'left' : 'right')}
                />
                <Solution
                    position={currentSlide === 2 ? 'center' : (currentSlide > 2 ? 'left' : 'right')}
                />
                <MarketValidation
                    position={currentSlide === 3 ? 'center' : (currentSlide > 3 ? 'left' : 'right')}
                />
                <MarketSize
                    position={currentSlide === 4 ? 'center' : (currentSlide > 4 ? 'left' : 'right')}
                />
                <ProductTitle
                    position={currentSlide === 5 ? 'center' : (currentSlide > 5 ? 'left' : 'right')}
                />
                <Photo
                    position={currentSlide === 6 ? 'center' : (currentSlide > 6 ? 'left' : 'right')}
                    photo="photo1.png"
                />
                <Product
                    position={currentSlide === 7 ? 'center' : (currentSlide > 7 ? 'left' : 'right')}
                />
                <Processors
                    position={currentSlide === 8 ? 'center' : (currentSlide > 8 ? 'left' : 'right')}
                />
                <ProductAutomated
                    position={currentSlide === 9 ? 'center' : (currentSlide > 9 ? 'left' : 'right')}
                />
                <ProductCapacity
                    position={currentSlide === 10 ? 'center' : (currentSlide > 10 ? 'left' : 'right')}
                />
                <ProductData
                    position={currentSlide === 11 ? 'center' : (currentSlide > 11 ? 'left' : 'right')}
                />
                <BusinessModel
                    position={currentSlide === 12 ? 'center' : (currentSlide > 12 ? 'left' : 'right')}
                />
                <MarketAdoption
                    position={currentSlide === 13 ? 'center' : (currentSlide > 13 ? 'left' : 'right')}
                />
                <Competition
                    position={currentSlide === 14 ? 'center' : (currentSlide > 14 ? 'left' : 'right')}
                />
                <Team
                    position={currentSlide === 15 ? 'center' : (currentSlide > 15 ? 'left' : 'right')}
                />
                <Financial
                    position={currentSlide === 16 ? 'center' : (currentSlide > 16 ? 'left' : 'right')}
                />
                <Portfolio
                    position={currentSlide === 17 ? 'center' : (currentSlide > 17 ? 'left' : 'right')}
                />
            </div>
            <Arrow
                currentSlide={currentSlide}
                goto={setCurrentSlide}
                type="next"
            />
        </div>
        <div className={styles.progress}>
            <div style={{ width: `${(currentSlide + 1) / (window as any).slideCount * 100}%` }} />
        </div>
    </>);
}

export default Carousel;
