import React, { FC, useEffect } from 'react';
import Carousel from './components/Carousel';

const App: FC = (): JSX.Element => {
    useEffect(() => {
		const vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${vh}px`);
	}, []);

    return (
        <Carousel />
    );
}

export default App;
