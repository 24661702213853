import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FC, useEffect, useState } from 'react';
import { c } from '../../../../lib/util';
import Logo from '../../../Logo';
import ShadowBackground from '../../../ShadowBackground';
import Slide, { SlidePosition, SlideProps } from '../../Slide';
import styles from './styles.module.scss';
import screen from './screen.png';

const ProductData: FC<SlideProps> = ({ position }): JSX.Element => {
    const [ delayedPosition, setDelayedPosition ] = useState<SlidePosition>();

    useEffect(() => {
        setTimeout(() => setDelayedPosition(position), position === 'center' ? 500 : 1);
    }, [position]);

    return (
        <Slide position={position}>
            <div className={c([styles.data, delayedPosition === 'center' && styles.isActive])}>
                <ShadowBackground bottom noImage random={10}>
                    <Slide.Body style={{ paddingBottom: 0, marginBottom: 0 }}>
                        <Logo className={styles.logo} />
                        <Slide.Title>Financieel overzicht &amp; data verzuim</Slide.Title>

                        <div className="row align-items-center" style={{ paddingTop: 16 }}>
                            <div className="col">
                                <div className={styles.screen}>
                                    <img src={screen} alt="" />
                                    <div className={styles.chartContainer}>
                                        {delayedPosition === 'center' && <HighchartsReact
                                            containerProps={{ style: { width: '100%' } }}
                                            highcharts={Highcharts}
                                            options={{
                                                credits: {
                                                    enabled: false,
                                                },
                                                legend: {
                                                    enabled: false,
                                                },
                                                chart: {
                                                    height: 200,
                                                    animation: {
                                                        defer: 1000,
                                                    }
                                                },
                                                title: {
                                                    text: '',
                                                    enabled: false,
                                                },
                                                xAxis: {
                                                    categories: ['Triage', 'PMO', 'Del.', 'Mel.', 'Lang.']
                                                },
                                                series: [{
                                                    type: 'column',
                                                    name: 'Regio Noord',
                                                    data: [3, 2, 1, 3, 4],
                                                    color: '#0c93d2',
                                                }, {
                                                    type: 'column',
                                                    name: 'Regio Zuid',
                                                    data: [2, 3, 5, 7, 6],
                                                    color: 'rgba(12, 147, 210, .6)',
                                                }, {
                                                    type: 'column',
                                                    name: 'Regio West',
                                                    data: [4, 3, 3, 9, 0],
                                                    color: 'rgba(12, 147, 210, .3)',
                                                }, {
                                                    type: 'spline',
                                                    name: 'Gemiddelde',
                                                    data: [3, 2.67, 3, 6.33, 3.33],
                                                    color: '#f4bd0e',
                                                }]
                                            }}
                                        />}
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className={styles.list}>
                                    <p>Real-time data en analyses</p>
                                    <p>Inzicht in en overzicht van acties en taakdelegaties</p>
                                    <p>Inkomsten en uitgaven werkgever</p>
                                    <p>Facturatie specialisten</p>
                                    <p>Geanonimiseerd verzuimpercentage op alle niveau's</p>
                                </div>

                                {/* <p style={{ marginTop: -16 }}>
                                    <Logo style={{ display: 'inline', fontSize: 16 }} /> voorziet in real-time data en analyses, zodat iedere stakeholder exact in kan zien welke taken er allemaal zijn uitgevoerd en welke opbrengsten daaraan zitten verbonden.
                                </p>
                                <p>
                                    Op grote schaal komt er inzicht in totalen over verzuimmeldingen en ziekteverloop, gebaseerd op dynamisch instelbare KPI's.
                                </p> */}
                            </div>
                        </div>
                    </Slide.Body>
                </ShadowBackground>
            </div>
        </Slide>
    );
}

export default ProductData;
