import { faFaceEyesXmarks, faListCheck, faShareNodes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { c } from '../../../../lib/util';
import Logo from '../../../Logo';
import ShadowBackground from '../../../ShadowBackground';
import Slide, { SlidePosition, SlideProps } from '../../Slide';
import styles from './styles.module.scss';

const Product: FC<SlideProps> = ({ position }): JSX.Element => {
    const [ state, setState ] = useState<number>(-1);
    const [ delayedPosition, setDelayedPosition ] = useState<SlidePosition>();

    useEffect(() => {
        setTimeout(() => setDelayedPosition(position), position === 'center' ? 500 : 1);
    }, [position]);

    useEffect(() => {
        setState(-1);
        if (delayedPosition === 'center') {
            setTimeout(() => { setState((s) => s + 1) }, 1000);
            setTimeout(() => { setState((s) => s + 1) }, 1100);
            setTimeout(() => { setState((s) => s + 1) }, 1200);
            setTimeout(() => { setState((s) => s + 1) }, 1300);
            setTimeout(() => { setState((s) => s + 1) }, 1400);
            setTimeout(() => { setState((s) => s + 1) }, 1500);
            setTimeout(() => { setState((s) => s + 1) }, 1600);

            setTimeout(() => { setState((s) => s + 1) }, 2600);
            setTimeout(() => { setState((s) => s + 1) }, 2700);
            setTimeout(() => { setState((s) => s + 1) }, 2800);
            setTimeout(() => { setState((s) => s + 1) }, 2900);
            setTimeout(() => { setState((s) => s + 1) }, 3000);
            setTimeout(() => { setState((s) => s + 1) }, 3100);
            setTimeout(() => { setState((s) => s + 1) }, 3200);
            
            setTimeout(() => { setState((s) => s + 1) }, 4200);
            setTimeout(() => { setState((s) => s + 1) }, 4300);
            setTimeout(() => { setState((s) => s + 1) }, 4400);
            setTimeout(() => { setState((s) => s + 1) }, 4500);
            setTimeout(() => { setState((s) => s + 1) }, 4600);
            setTimeout(() => { setState((s) => s + 1) }, 4700);
            setTimeout(() => { setState((s) => s + 1) }, 4800);
        }
    }, [delayedPosition]);

    return (
        <Slide position={position}>
            <div className={c([styles.product, delayedPosition === 'center' && styles.isActive])}>
                <ShadowBackground noImage bottom random={7}>
                    <Slide.Body>
                        <Logo
                            className={styles.logo}
                            // white
                        />
                        <Slide.Title>Digital First</Slide.Title>
                        
                        <div className="row">
                            <div className="col-md-4">
                                <div className={styles.column}>
                                    <div
                                        className={c([
                                            styles.columnTitle,
                                            state >= 0 && styles.show,
                                        ])}
                                    >
                                        "Digital AI" zorg
                                    </div>
                                    <div
                                        className={c([
                                            styles.columnSubtitle,
                                            state >= 1 && styles.show,
                                        ])}
                                    >
                                        Zelfregie werknemers
                                    </div>
                                    <div
                                        className={c([
                                            styles.columnCols,
                                            state >= 2 && styles.show,
                                        ])}
                                    >
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div
                                                    style={{ backgroundImage: `url(/phone.png)` }}
                                                    className={c([
                                                        styles.col,
                                                        styles.phone,
                                                        state >= 3 && styles.show,
                                                    ])}
                                                >
                                                    <FontAwesomeIcon icon={faFaceEyesXmarks} />
                                                    Ziek melden
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div
                                                    style={{ backgroundImage: `url(/phone.png)` }}
                                                    className={c([
                                                        styles.col,
                                                        styles.phone,
                                                        state >= 4 && styles.show,
                                                    ])}
                                                >
                                                    <FontAwesomeIcon icon={faListCheck} />
                                                    AI Triage
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div
                                                    style={{ backgroundImage: `url(/phone.png)` }}
                                                    className={c([
                                                        styles.col,
                                                        styles.phone,
                                                        state >= 5 && styles.show,
                                                    ])}
                                                >
                                                    <FontAwesomeIcon icon={faShareNodes} />
                                                    Digitaal dossier delen
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={c([
                                            styles.columnFooter,
                                            state >= 6 && styles.show,
                                        ])}
                                    >
                                        Engagement en stimulering zelfredzaamheid
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className={styles.column}>
                                    <div
                                        className={c([
                                            styles.columnTitle,
                                            state >= 7 && styles.show,
                                        ])}
                                    >
                                        "Virtuele" zorg
                                    </div>
                                    <div
                                        className={c([
                                            styles.columnSubtitle,
                                            state >= 8 && styles.show,
                                        ])}
                                    >
                                        Supervisie stafarts
                                    </div>
                                    <div
                                        className={c([
                                            styles.columnCols,
                                            state >= 9 && styles.show,
                                        ])}
                                    >
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div
                                                    style={{ backgroundImage: `url(/phone.png)` }}
                                                    className={c([
                                                        styles.col,
                                                        styles.phone,
                                                        state >= 10 && styles.show,
                                                    ])}
                                                >
                                                    Informatie naar de juiste specialist
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div
                                                    style={{ backgroundImage: `url(/phone.png)` }}
                                                    className={c([
                                                        styles.col,
                                                        styles.phone,
                                                        state >= 11 && styles.show,
                                                    ])}
                                                >
                                                    Borging process taak-delegatie
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div
                                                    style={{ backgroundImage: `url(/phone.png)` }}
                                                    className={c([
                                                        styles.col,
                                                        styles.phone,
                                                        state >= 12 && styles.show,
                                                    ])}
                                                >
                                                    Virtual consultants
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={c([
                                            styles.columnFooter,
                                            state >= 13 && styles.show,
                                        ])}
                                        // style={{ fontSize: 12 }}
                                    >
                                        Opschaalbare bedrijfsgezondheids-zorg met de beste zorgteams
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className={styles.column}>
                                    <div
                                        className={c([
                                            styles.columnTitle,
                                            state >= 14 && styles.show,
                                        ])}
                                    >
                                        "Fysieke" zorg
                                    </div>
                                    <div
                                        className={c([
                                            styles.columnSubtitle,
                                            state >= 15 && styles.show,
                                        ])}
                                    >
                                        Doorverwijzing zorgspecialist
                                    </div>
                                    <div
                                        className={c([
                                            styles.columnCols,
                                            state >= 16 && styles.show,
                                        ])}
                                    >
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div
                                                    className={c([
                                                        styles.col,
                                                        state >= 17 && styles.show,
                                                    ])}
                                                >
                                                    Point of care
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div
                                                    className={c([
                                                        styles.col,
                                                        state >= 18 && styles.show,
                                                    ])}
                                                >
                                                    Aan huis / Op locatie
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div
                                                    className={c([
                                                        styles.col,
                                                        state >= 19 && styles.show,
                                                    ])}
                                                >
                                                    Klinisch
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={c([
                                            styles.columnFooter,
                                            state >= 20 && styles.show,
                                        ])}
                                    >
                                        Tijdige en gerichte persoonlijke zorg wanneer het nodig is
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.split}>
                            <div className={styles.splitFirst}>
                                Digital First
                            </div>
                            <div className={styles.splitLast}>
                                Traditioneel
                            </div>
                        </div>
                    </Slide.Body>
                </ShadowBackground>
            </div>
        </Slide>
    );
}

export default Product;
