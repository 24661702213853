import React, { FC, HTMLAttributes } from 'react';
import { c } from '../../lib/util';
import styles from './styles.module.scss';

interface ShadowBackgroundProps extends HTMLAttributes<HTMLDivElement> {
    bottom?: boolean,
    noImage?: boolean,
    random: number
    shadow?: boolean,
    photo?: string,
}

const ShadowBackground: FC<ShadowBackgroundProps> = ({
    bottom,
    children,
    noImage,
    random,
    shadow,
    photo,
}): JSX.Element => {
    return (<>
        {!noImage && (
            <div
                className={c([
                    styles.background,
                    shadow === false && styles.noShadow,
                    bottom && styles.bottom,
                ])}
                style={{ backgroundImage: `url(/${photo || (random % 2 === 0 ? '2.png' : '1.png')})` }}
            />
        )}
        <div
            className={c([
                styles.container,
                (bottom || shadow === false) && styles.noShadow
            ])}>
            {children}
        </div>
    </>);
}

export default ShadowBackground;
