import React, { FC, HTMLAttributes } from 'react';
import { c } from '../../lib/util';
import styles from './styles.module.scss';

export type SlidePosition = 'left' | 'center' | 'right';

export interface SlideProps extends HTMLAttributes<HTMLDivElement> {
    position: SlidePosition,
}

const Body: FC<HTMLAttributes<HTMLDivElement>> = ({ children, style }): JSX.Element => {
    return (
        <div className={styles.slideBody} style={style}>
            {children}
        </div>
    )
}

const Title: FC<HTMLAttributes<HTMLDivElement>> = ({ children, style }): JSX.Element => {
    return (
        <div className={styles.slideTitle} style={style}>
            <span />
            <h1>{children}</h1>
        </div>
    )
}

class Slide extends React.Component<SlideProps> {
    static Body = Body;
    static Title = Title;
    
    render = (): JSX.Element => {
        const { className, children, position } = this.props;
    
        return (
            <div
                className={c([
                    styles.slide,
                    styles[position],
                    className,
                ])}
            >
                {children}
            </div>
        );
    }
}

export default Slide;
