import React, { FC, useEffect, useState } from 'react';
import { c } from '../../../../lib/util';
import Logo from '../../../Logo';
import ShadowBackground from '../../../ShadowBackground';
import map from './nederland.png';
import phone from './phone.png';
import doctor from './doctor.png';
import Slide, { SlidePosition, SlideProps } from '../../Slide';
import styles from './styles.module.scss';
import Loader from '../../../Loader';
import call from './nurse_doctor_2-1.png';

const ProductCapacity: FC<SlideProps> = ({ position }): JSX.Element => {
    const [ state, setState ] = useState<number>(-1);
    const [ delayedPosition, setDelayedPosition ] = useState<SlidePosition>();

    useEffect(() => {
        setTimeout(() => setDelayedPosition(position), position === 'center' ? 500 : 1);
    }, [position]);

    useEffect(() => {
        setState(-1);
        if (delayedPosition === 'center') {
            setTimeout(() => { setState((s) => s + 1) }, 1000);
            setTimeout(() => { setState((s) => s + 1) }, 1500);
            setTimeout(() => { setState((s) => s + 1) }, 1800);
            setTimeout(() => { setState((s) => s + 1) }, 2000);
            setTimeout(() => { setState((s) => s + 1) }, 3000);
            setTimeout(() => { setState((s) => s + 1) }, 3300);
            setTimeout(() => { setState((s) => s + 1) }, 3750);
            setTimeout(() => { setState((s) => s + 1) }, 4900);
            setTimeout(() => { setState((s) => s + 1) }, 5300);

            // setTimeout(() => { setState((s) => s + 1) }, 7200);

            // setTimeout(() => { setState((s) => s + 1) }, 9000);
            // setTimeout(() => { setState((s) => s + 1) }, 10500);
            // setTimeout(() => { setState((s) => s + 1) }, 12000);
            // setTimeout(() => { setState((s) => s + 1) }, 13000);
            // setTimeout(() => { setState((s) => s + 1) }, 13300);
            setTimeout(() => { setState((s) => s + 1) }, 5300);

            setTimeout(() => { setState((s) => s + 1) }, 7000);
            setTimeout(() => { setState((s) => s + 1) }, 8500);
            setTimeout(() => { setState((s) => s + 1) }, 10000);
            setTimeout(() => { setState((s) => s + 1) }, 11000);
            setTimeout(() => { setState((s) => s + 1) }, 11300);
        }
    }, [delayedPosition]);

    return (
        <Slide position={position}>
            <div className={c([styles.capacity, delayedPosition === 'center' && styles.isActive])}>
                <ShadowBackground bottom random={9}>
                    <Slide.Body>
                        <Logo className={styles.logo} />
                        <Slide.Title>Grotere capaciteit door zelfregie,<br />AI Triage en virtuele zorg</Slide.Title>

                        <div className={styles.box}>
                            <p>
                                Altijd een bedrijfsarts, verpleegkundige of casemanager beschikbaar.
                            </p>

                            <div
                                className={c([
                                    styles.phone,
                                    state >= 2 && styles.phoneShow
                                ])}
                            >
                                <img className={styles.bg} src={phone} alt="" />

                                <div
                                    className={c([
                                        styles.triage,
                                        state >= 3 && state <= 6 && styles.show,
                                    ])}
                                >
                                    <div className={c([state === 4 && styles.click])} />
                                    <div className={c([state === 5 && styles.click])} />
                                    <div className={c([state >= 6 && styles.click])} />
                                    <div />
                                    <div />
                                </div>

                                <div
                                    className={c([
                                        styles.doctor,
                                        (state === 80 || state === 14) && styles.show
                                    ])}
                                >
                                    {state >= 14 && <img src={call} alt="" />}
                                    {/* <img src={doctor} alt="" />
                                    <p>
                                        {state === 8
                                            ? 'Geen arts beschikbaar in uw regio'
                                            : 'Dossier delen'
                                        }
                                    </p> */}
                                </div>

                                {state >= 9 && state <= 13 && <div
                                    className={styles.loader}
                                >
                                    <Loader />
                                    <p>Zoeken...</p>
                                </div>}
                            </div>

                            <div
                                className={c([
                                    styles.line,
                                    styles.line1,
                                    state >= 1 && styles.a,
                                ])}
                            />

                            <div className={styles.map}>
                                <img src={map} alt="" />
                                <div
                                    className={c([
                                        styles.dot,
                                        styles.rotterdam,
                                        state >= 0 && styles.animate
                                    ])}
                                />

                                <div
                                    className={c([
                                        styles.dot,
                                        styles.groningen,
                                        state >= 10 && styles.animate
                                    ])}
                                />
                                <div
                                    className={c([
                                        styles.dot,
                                        styles.overijssel,
                                        (state === 11 || state === 12) && styles.animate,
                                        state >= 13 && styles.large
                                    ])}
                                >
                                    <img src={doctor} alt="" />
                                    <img src={doctor} alt="" />
                                    <img src={doctor} alt="" />
                                </div>
                                <div
                                    className={c([
                                        styles.dot,
                                        styles.eindhoven,
                                        state >= 12 && styles.animate
                                    ])}
                                />
                            </div>
                        </div>
                    </Slide.Body>
                </ShadowBackground>
            </div>
        </Slide>
    );
}

export default ProductCapacity;
