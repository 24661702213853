import React, { FC, useEffect, useState } from 'react';
import { c } from '../../../../lib/util';
import Logo from '../../../Logo';
import ShadowBackground from '../../../ShadowBackground';
import Slide, { SlidePosition, SlideProps } from '../../Slide';
import styles from './styles.module.scss';

const Problem: FC<SlideProps> = ({ position }): JSX.Element => {
    const [ delayedPosition, setDelayedPosition ] = useState<SlidePosition>();

    useEffect(() => {
        setTimeout(() => setDelayedPosition(position), position === 'center' ? 500 : 1);
    }, [position]);

    return (
        <Slide position={position}>
            <div className={c([styles.problem, delayedPosition === 'center' && styles.isActive])}>
                <ShadowBackground bottom random={2}>
                    <Slide.Body>
                        <Logo
                            className={styles.logo}
                            // white
                        />
                        <Slide.Title>Probleem</Slide.Title>
                        <p style={{ marginBottom: 32, fontSize: 18 }}>
                            De traditionele bedrijfsgezondheidszorg:
                        </p>

                        <div className={styles.list}>
                            <p><b>is niet opschaalbaar</b> door o.a. een enorm tekort aan bedrijfsartsten;</p>
                            <p><b>besteed te veel kostbare tijd</b> aan triage, taakdelegatie en het opbouwen van (medische) dossiers;</p>
                            <p><b>veroorzaakt lange wachttijden</b> en onnodig hoge verzuimkosten voor <b>werkgevers</b>;</p>
                        </div>
                    </Slide.Body>
                </ShadowBackground>
            </div>
        </Slide>
    );
}

export default Problem;
