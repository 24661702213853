import { faBooksMedical, faChartMixed, faHeadSideHeart, faIdCard, faRobot, faStethoscope } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useEffect, useState } from 'react';
import { c } from '../../../../lib/util';
import IconBlock from '../../../IconBlock';
import Logo from '../../../Logo';
import ShadowBackground from '../../../ShadowBackground';
import Slide, { SlidePosition, SlideProps } from '../../Slide';
import styles from './styles.module.scss';

const icons = [
    { icon: faRobot, title: 'Triage o.b.v. machinelearning', content: 'en chatbot AI technologie' },
    { icon: faStethoscope, title: 'Stimulering zelfredzaamheid', content: 'delen van kennis over ziekten en  symptonen' },
    { icon: faIdCard, title: 'Zelfregie ziekmelden', content: 'opbouwen en delen verzuim- en medisch dossier' },
    { icon: faBooksMedical, title: 'Minder administratieve handelingen', content: 'minder lange wachttijden' },
    { icon: faHeadSideHeart, title: 'Altijd een zorgspecialist beschikbaar', content: 'bedrijfsarts, casemanager en verpleegkundige, etc.' },
    { icon: faChartMixed, title: 'Real-time inzicht in data', content: 'leidt tot minder verzuim en lagere kosten' },
]

const Solution: FC<SlideProps> = ({ position }): JSX.Element => {
    const [ state, setState ] = useState<number>(0);
    const [ delayedPosition, setDelayedPosition ] = useState<SlidePosition>();

    useEffect(() => {
        setTimeout(() => setDelayedPosition(position), position === 'center' ? 500 : 1);
    }, [position]);

    useEffect(() => {
        setState(0);
        if (delayedPosition === 'center') {
            setTimeout(() => { setState((s) => s + 1) }, 500);
            setTimeout(() => { setState((s) => s + 1) }, 2000);
            setTimeout(() => { setState((s) => s + 1) }, 3500);
            setTimeout(() => { setState((s) => s + 1) }, 4500);
            setTimeout(() => { setState((s) => s + 1) }, 4800);
            setTimeout(() => { setState((s) => s + 1) }, 5100);
            setTimeout(() => { setState((s) => s + 1) }, 5400);
            setTimeout(() => { setState((s) => s + 1) }, 5700);
            setTimeout(() => { setState((s) => s + 1) }, 6000);
            setTimeout(() => { setState((s) => s + 1) }, 6300);
        }
    }, [delayedPosition]);

    return (
        <Slide position={position}>
            <div className={c([styles.solution, delayedPosition === 'center' && styles.isActive])}>
                <ShadowBackground random={3}>
                    <Slide.Body>
                        <Logo
                            className={styles.logo}
                            white
                        />
                        <Slide.Title>Oplossing</Slide.Title>

                        <p>
                            Laagdrempelige en <b>opschaalbare</b> "Digital first" bedrijfsgezondheidszorg.
                        </p>

                        <div className={styles.content}>
                            <div
                                className={c([
                                    styles.graph,
                                    state > 3 && styles.smallGraph,
                                ])}
                            >
                                <div className={styles.innerGraph}>
                                    <div
                                        className={c([
                                            styles.columnContainer,
                                            state > 0 && styles.animate,
                                        ])}
                                        style={{ opacity: state > 0 ? 1 : 0 }}
                                    >
                                        <div className={styles.label}>
                                            1.000<br />zieke wkn
                                        </div>
                                        <div className={styles.column}>
                                            Bedrijfsarts
                                        </div>
                                    </div>
                                    <div
                                        className={c([
                                            styles.columnContainer,
                                            state > 1 && styles.animate,
                                        ])}
                                        style={{ opacity: state > 1 ? 1 : 0 }}
                                    >
                                        <div className={styles.label}>
                                            100.000<br />zieke wkn
                                        </div>
                                        <div className={styles.column}>
                                            Arbodienst
                                        </div>
                                    </div>
                                    <div
                                        className={c([
                                            styles.columnContainer,
                                            state > 2 && styles.animate,
                                        ])}
                                        style={{ opacity: state > 2 ? 1 : 0 }}
                                    >
                                        <div className={styles.label}>
                                            1.000.000<br />zieke wkn
                                        </div>
                                        <div className={styles.column}>
                                            <Logo white />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={c([
                                styles.blocks,
                                state > 3 && styles.show,
                            ])}>
                                {icons.map((icon, index) => (
                                    <div
                                        key={`icon-${index}`}
                                        className={c([
                                            styles.block,
                                            state > index + 4 && styles.show,
                                        ])}
                                    >
                                        <IconBlock
                                            row
                                            {...icon}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Slide.Body>
                </ShadowBackground>
            </div>
        </Slide>
    );
}

export default Solution;
