import { faApartment, faBusinessTime, faUserDoctorHair } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useEffect, useState } from 'react';
import { c, countUp } from '../../../../lib/util';
import CounterBlock from '../../../CounterBlock';
import Logo from '../../../Logo';
import ShadowBackground from '../../../ShadowBackground';
import Slide, { SlidePosition, SlideProps } from '../../Slide';
import styles from './styles.module.scss';

const MarketSize: FC<SlideProps> = ({ position }): JSX.Element => {
    const [ delayedPosition, setDelayedPosition ] = useState<SlidePosition>();

    useEffect(() => {
        setTimeout(() => setDelayedPosition(position), position === 'center' ? 500 : 1);
    }, [position]);

    useEffect(() => {
        countUp(document.querySelector('#count-3 > div > span') as HTMLSpanElement, 2000000, 2500, false)();
        countUp(document.querySelector('#count-4 > div > span') as HTMLSpanElement, 9000000, 3500, false)();
        countUp(document.querySelector('#count-5 > div > span') as HTMLSpanElement, 1624, 2000, false)();
    }, [delayedPosition]);

    return (
        <Slide position={position}>
            <div className={c([styles.marketValidation, delayedPosition === 'center' && styles.isActive])}>
                <ShadowBackground random={5} bottom>
                    <Slide.Body>
                        <Logo
                            className={styles.logo}
                        />
                        <Slide.Title>Market size</Slide.Title>

                        <div className={styles.blocks}>
                            <div className={styles.block} id="count-3">
                                <CounterBlock
                                    icon={faApartment}
                                    number={2000000}
                                    title="bedrijven"
                                />
                            </div>
                            <div className={styles.block} id="count-4">
                                <CounterBlock
                                    icon={faBusinessTime}
                                    number={670000}
                                    title="werkzame beroepsbevolking"
                                />
                            </div>
                            <div className={styles.block} id="count-5">
                                <CounterBlock
                                    icon={faUserDoctorHair}
                                    number={1624}
                                    title="bedrijfsartsen"
                                />
                            </div>
                        </div>
                        {/* <p className={styles.info}>
                            Zoveel %
                        </p> */}
                    </Slide.Body>
                </ShadowBackground>
            </div>
        </Slide>
    );
}

export default MarketSize;
