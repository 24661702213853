import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect } from 'react';
import { c } from '../../lib/util';
import styles from './styles.module.scss';

interface ArrowProps {
    currentSlide: number,
    goto: (slide: number) => void,
    type: 'next' | 'prev',
}

const Arrow: FC<ArrowProps> = ({ currentSlide, goto, type }): JSX.Element => {
    useEffect(() => {
        const handleKeyUp = (e: KeyboardEvent): void => {
            if (e.key === 'ArrowLeft' && type === 'prev') {
                e.preventDefault();
                if (currentSlide > 0) {
                    goto(currentSlide - 1);
                } else {
                    dispatchEvent(new CustomEvent('arrow', { detail: { type: 'prev' }}));
                }
            } else if (e.key === 'ArrowRight' && type === 'next') {
                e.preventDefault();
                if (currentSlide < (window as any).slideCount - 1) {
                    goto(currentSlide + 1);
                } else {
                    dispatchEvent(new CustomEvent('arrow', { detail: { type: 'next' }}));
                }
            }
        }

        window.addEventListener('keyup', handleKeyUp, true);
        return () => window.removeEventListener('keyup', handleKeyUp, true);
    }, [currentSlide, goto, type]);

    return <button
        className={c([styles.arrow, type === 'prev' ? styles.arrowPrev : styles.arrowNext])}
        onClick={() => {
            window.dispatchEvent(new KeyboardEvent('keyup', {
                key: `Arrow${type === 'next' ? 'Right' : 'Left'}`
            }));
        }}
        type="button"
    >
        <FontAwesomeIcon icon={type === 'prev' ? faChevronLeft : faChevronRight} />
    </button>;

    // return (type === 'prev' && currentSlide === 0) || (type === 'next' && currentSlide >= (window as any).slideCount - 1) ? (
    //     <button
    //         className={styles.arrow}
    //         style={{ opacity: .1 }}
    //         type="button"
    //     >
    //         <FontAwesomeIcon icon={type === 'prev' ? faChevronLeft : faChevronRight} />
    //     </button>
    // ) : (
    //     <button
    //         className={c([styles.arrow, type === 'prev' ? styles.arrowPrev : styles.arrowNext])}
    //         onClick={() => goto(type === 'prev' ? currentSlide - 1 : currentSlide + 1)}
    //         type="button"
    //     >
    //         <FontAwesomeIcon icon={type === 'prev' ? faChevronLeft : faChevronRight} />
    //     </button>
    // );
}

export default Arrow;
