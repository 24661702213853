import React, { FC, useEffect, useState } from 'react';
import { c } from '../../../../lib/util';
import TeamMember from '../../../TeamMember';
import Logo from '../../../Logo';
import ShadowBackground from '../../../ShadowBackground';
import Slide, { SlidePosition, SlideProps } from '../../Slide';
import styles from './styles.module.scss';
import aris from './aris.jpg';
import jelle from './me.jpg';

const Team: FC<SlideProps> = ({ position }): JSX.Element => {
    const [ delayedPosition, setDelayedPosition ] = useState<SlidePosition>();

    useEffect(() => {
        setTimeout(() => setDelayedPosition(position), position === 'center' ? 500 : 1);
    }, [position]);

    return (
        <Slide position={position}>
            <div className={c([styles.team, delayedPosition === 'center' && styles.isActive])}>
                <ShadowBackground random={10} bottom>
                    <Slide.Body>
                        <Logo
                            className={styles.logo}
                        />
                        <Slide.Title style={{ marginBottom: '1rem' }}>Team</Slide.Title>

                        <div className="row">
                            <div className="col-1" />
                            <div className="col-5">
                                <div className={styles.block}>
                                    <TeamMember
                                        avatar={aris}
                                        title="Aris Dorresteijn"
                                        content={<span>MostWantIT<br /><small>(Research &amp; Innovation)</small></span>}
                                    />
                                </div>
                            </div>
                            <div className="col-5">
                                <div className={styles.block}>
                                    <TeamMember
                                        avatar={jelle}
                                        title="Jelle van der Coelen"
                                        content={<span>MostWantIT<br /><small>(Lead Developer)</small></span>}
                                    />
                                </div>
                            </div>
                            <div className="col-1" />
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Logo />
                        </div>
                    </Slide.Body>
                </ShadowBackground>
            </div>
        </Slide>
    );
}

export default Team;
