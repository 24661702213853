import React, { FC, HTMLAttributes } from 'react';
import { c } from '../../lib/util';
import styles from './styles.module.scss';

interface LogoProps extends HTMLAttributes<HTMLDivElement> {
    white?: boolean,
}

const Logo: FC<LogoProps> = ({ className, white, ...props }): JSX.Element => {
    return (
        <div
            className={c([styles.logo, className, white && styles.white])}
            {...props}
        >
            my<strong>health</strong>id
        </div>
    );
}

export default Logo;
