import React, { FC, useEffect, useState } from 'react';
import { c } from '../../../../lib/util';
import ShadowBackground from '../../../ShadowBackground';
import Slide, { SlidePosition, SlideProps } from '../../Slide';
import styles from './styles.module.scss';

const Photo: FC<SlideProps & { photo: string }> = ({ photo, position }): JSX.Element => {
    const [ state, setState ] = useState<number>(-1);
    const [ delayedPosition, setDelayedPosition ] = useState<SlidePosition>();

    useEffect(() => {
        setTimeout(() => setDelayedPosition(position), position === 'center' ? 500 : 1);
    }, [position]);

    useEffect(() => {
        setState(-1);
        if (delayedPosition === 'center') {
            setTimeout(() => setState((c) => c + 1), 500);
            setTimeout(() => setState((c) => c + 1), 1100);
        }
    }, [delayedPosition]);

    return (
        <Slide position={position}>
            <div className={c([styles.photo, delayedPosition === 'center' && styles.isActive])}>
                <ShadowBackground
                    random={6}
                    photo={photo}
                    shadow={false}
                >
                    <Slide.Body>
                        <div className={styles.mask}>
                            <img src="/photo2.png" alt="" />
                        </div>

                        <div className={c([styles.title, styles.title1, state >= 0 && styles.show])}>
                            Klaar met lange wachttijden?
                        </div>
                        <div className={c([styles.title, styles.title2, state >= 1 && styles.show])}>
                            Dat gaat nu veranderen!
                        </div>
                    </Slide.Body>
                </ShadowBackground>
            </div>
        </Slide>
    );
}

export default Photo;
