import React, { FC, useEffect, useState } from 'react';
import { c } from '../../../../lib/util';
import Logo from '../../../Logo';
import ShadowBackground from '../../../ShadowBackground';
import Slide, { SlidePosition, SlideProps } from '../../Slide';
import styles from './styles.module.scss';
import intro from './intro.png';
import map from '../ProductCapacity/nederland-doc.png';

const ProductTitle: FC<SlideProps> = ({ position }): JSX.Element => {
    const [ state, setState ] = useState<number>(-1);
   const [ delayedPosition, setDelayedPosition ] = useState<SlidePosition>();

    useEffect(() => {
        setTimeout(() => setDelayedPosition(position), position === 'center' ? 500 : 1);
    }, [position]);

    useEffect(() => {
        setState(-1);
        if (delayedPosition === 'center') {
            setTimeout(() => { setState((s) => s + 1) }, 500);
            setTimeout(() => { setState((s) => s + 1) }, 800);
            setTimeout(() => { setState((s) => s + 1) }, 900);
            setTimeout(() => { setState((s) => s + 1) }, 1000);
        }
    }, [delayedPosition]);

    return (
        <Slide position={position}>
            <div className={c([styles.productTitle, delayedPosition === 'center' && styles.isActive])}>
                <ShadowBackground random={6}>
                    <Slide.Body>
                        <Logo
                            className={styles.logo}
                            white
                        />
                        <Slide.Title>Product</Slide.Title>

                        <div className="row align-items-center">
                            <div className="col-4">
                                <div className={styles.triage}>
                                    <div className={c([styles.block, styles.primary, state >= 0 && styles.show])}>
                                        Heb je hoofdpijn?
                                    </div>
                                    <div className={c([styles.block, state >= 1 && styles.show])}>
                                        Het komt en gaat weer weg
                                    </div>
                                    <div className={c([styles.block, state >= 2 && styles.show])}>
                                        Het is er de hele tijd
                                    </div>
                                    <div className={c([styles.block, state >= 3 && styles.show])}>
                                        Toevoegen
                                    </div>
                                    <div className={c([styles.line1, state >= 3 && styles.show])} />
                                    <div className={c([styles.line2, state >= 3 && styles.show])} />
                                </div>
                            </div>
                            <div className="col-3">
                                <div
                                    className={styles.phone}
                                    style={{ backgroundImage: 'url(/phone.png)' }}
                                >
                                    <img src={intro} alt="" />
                                </div>
                            </div>
                            <div className="col-5">
                                <div className={styles.map}>
                                    <img
                                        src={map}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </Slide.Body>
                </ShadowBackground>
            </div>
        </Slide>
    );
}

export default ProductTitle;
