import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { c } from '../../lib/util';
import styles from './styles.module.scss';

interface IconBlockProps {
    content?: string,
    icon: IconDefinition,
    row?: boolean,
    title: string,
}

const IconBlock: FC<IconBlockProps> = ({ content, icon, row, title }): JSX.Element => {
    return (
        <div className={c([styles.container, row && styles.row])}>
            <div className={styles.icon}>
                <FontAwesomeIcon icon={icon} />
            </div>
            <div className={styles.content}>
                <h3>{title}</h3>
                {content && <p>{content}</p>}
            </div>
        </div>
    );
}

export default IconBlock;
