import React, { FC, useEffect, useState } from 'react';
import { c } from '../../../../lib/util';
import Logo from '../../../Logo';
import ShadowBackground from '../../../ShadowBackground';
import Slide, { SlidePosition, SlideProps } from '../../Slide';
import styles from './styles.module.scss';

const ProductAutomated: FC<SlideProps> = ({ position }): JSX.Element => {
    const [ line, setLine ] = useState<number>(-1);
    const [ state, setState ] = useState<number>(-1);
    const [ delayedPosition, setDelayedPosition ] = useState<SlidePosition>();

    useEffect(() => {
        setTimeout(() => setDelayedPosition(position), position === 'center' ? 500 : 1);
    }, [position]);

    useEffect(() => {
        setState(-1);
        setLine(-1);
        if (delayedPosition === 'center') {
            setTimeout(() => { setState((s) => s + 1) }, 1000);
            setTimeout(() => { setState((s) => s + 1) }, 1500);
            setTimeout(() => { setState((s) => s + 1) }, 2000);
            setTimeout(() => { setState((s) => s + 1) }, 2300);
            setTimeout(() => { setState((s) => s + 1) }, 2600);
            setTimeout(() => { setState((s) => s + 1) }, 2150);
            setTimeout(() => { setState((s) => s + 1) }, 2450);
            setTimeout(() => { setState((s) => s + 1) }, 2800);

            // line
            setTimeout(() => { setLine((s) => s + 1) }, 1250);
            setTimeout(() => { setLine((s) => s + 1) }, 1251);
            setTimeout(() => { setLine((s) => s + 1) }, 2300);
            setTimeout(() => { setLine((s) => s + 1) }, 2400);
            setTimeout(() => { setLine((s) => s + 1) }, 2500);
            setTimeout(() => { setLine((s) => s + 1) }, 2600);
            setTimeout(() => { setLine((s) => s + 1) }, 2450);
            setTimeout(() => { setLine((s) => s + 1) }, 2800);
            setTimeout(() => { setLine((s) => s + 1) }, 3000);
        }
    }, [delayedPosition]);

    return (
        <Slide position={position}>
            <div className={c([styles.automated, delayedPosition === 'center' && styles.isActive])}>
                <ShadowBackground
                    bottom
                    random={8}
                >
                    <Slide.Body>
                        <Logo className={styles.logo} />
                        <Slide.Title>Eenvoudig, geborgd en<br />geautomatiseerd proces</Slide.Title>

                        <div className={styles.box}>
                            <div className={c([styles.object, styles.employee])}>
                                Werknemer
                                <span>Ziekmelding</span>
                            </div>
                            <div className={c([styles.line, styles.line2, styles.v, line >= 1 && styles.a])} />

                            <div className={c([styles.object, styles.employer, state >= 0 && styles.o])}>
                                Werkgever
                            </div>
                            <div className={c([styles.line, styles.line1, line >= 0 && styles.a])}>
                                <span>AI Triage</span>
                            </div>
                            <div className={c([styles.dots, styles.dots1, styles.v, line >= 7 && styles.a])} />
                            <div className={c([styles.dots, styles.dots2, line >= 7 && styles.a])} />
                            
                            <div className={c([styles.object, styles.case, state >= 1 && styles.o])}>
                                Casemanager
                            </div>
                            <div className={c([styles.line, styles.line3, line >= 2 && styles.a])} />
                            
                            <div className={c([styles.object, styles.doctor, styles.doctor1, state >= 2 && styles.o])}>
                                Bedrijfsarts
                            </div>
                            <div className={c([styles.line, styles.line4, styles.v, line >= 3 && styles.a])} />
                            <div className={c([styles.object, styles.doctor, styles.doctor2, state >= 3 && styles.o])}>
                                Bedrijfsarts
                            </div>
                            <div className={c([styles.line, styles.line5, styles.v, line >= 4 && styles.a])} />
                            <div className={c([styles.object, styles.doctor, styles.doctor3, state >= 4 && styles.o])}>
                                Bedrijfsarts
                            </div>
                            <div className={c([styles.line, styles.line6, styles.v, line >= 5 && styles.a])} />

                            <div className={c([styles.object, styles.nurse, styles.nurse1, state >= 5 && styles.o])}>
                                Verpleegkundige
                            </div>
                            <div className={c([styles.line, styles.line7, styles.v, line >= 6 && styles.a])} />
                            <div className={c([styles.object, styles.nurse, styles.nurse2, state >= 6 && styles.o])}>
                                Verpleegkundige
                            </div>
                            <div className={c([styles.line, styles.line8, styles.v, line >= 6 && styles.a])} />
                            <div className={c([styles.object, styles.staff, state >= 7 && styles.o])}>
                                Stafarts
                            </div>
                            <div className={c([styles.line, styles.line9, styles.v, line >= 7 && styles.a])} />
                            <div className={c([styles.region, line >= 8 && styles.show])}>
                                <span>Supervisie / Taakdelegatie</span>
                            </div>

                            <div className={c([styles.dots, styles.dots3, styles.v, line >= 5 && styles.a])} />
                            <div className={c([styles.dots, styles.dots4, line >= 5 && styles.a])} />
                        </div>
                    </Slide.Body>
                </ShadowBackground>
            </div>
        </Slide>
    );
}

export default ProductAutomated;
