import React, { FC, useEffect, useState } from 'react';
import { c, countUp } from '../../../../lib/util';
import CounterBlock from '../../../CounterBlock';
import Logo from '../../../Logo';
import ShadowBackground from '../../../ShadowBackground';
import Slide, { SlidePosition, SlideProps } from '../../Slide';
import styles from './styles.module.scss';

const BusinessModel: FC<SlideProps> = ({ position }): JSX.Element => {
    const [ delayedPosition, setDelayedPosition ] = useState<SlidePosition>();

    useEffect(() => {
        setTimeout(() => setDelayedPosition(position), position === 'center' ? 500 : 1);
    }, [position]);

    useEffect(() => {
        countUp(document.querySelector('#count-6 > div > span') as HTMLSpanElement, 270000, 2500, false)();
        countUp(document.querySelector('#count-7 > div > span') as HTMLSpanElement, 47000, 2500, false)();
        countUp(document.querySelector('#count-8 > div > span') as HTMLSpanElement, 58608547, 3500, false)();
        countUp(document.querySelector('#count-13 > div > span') as HTMLSpanElement, 8791282, 2500, false)();
    }, [delayedPosition]);

    return (
        <Slide position={position}>
            <div className={c([styles.businessModel, delayedPosition === 'center' && styles.isActive])}>
                <ShadowBackground bottom noImage random={7}>
                    <Slide.Body>
                        <Logo
                            className={styles.logo}
                            white
                        />
                        <Slide.Title>Business model</Slide.Title>

                        <div className="row" style={{ marginTop: -32 }}>
                            <div className="col-6">
                                <div className={styles.block} id="count-6">
                                    <CounterBlock
                                        number={270000}
                                        title="Werknemers"
                                        content="dat is 3% van de werkzame beroepsbevolking"
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className={styles.block} id="count-7">
                                    <CounterBlock
                                        number={47000}
                                        title="Gemiddelde loonsom"
                                        content="Uitgaande van 100% voltijd banen"
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className={styles.block} id="count-8">
                                    <CounterBlock
                                        number={58608547}
                                        title="Kosten arbodienst + verzuimbegeleiding"
                                        content="Op basis van een gemiddeld verzuimpercentage van 5.5%"
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className={styles.block} id="count-13">
                                    <CounterBlock
                                        number={8791282}
                                        title="15% marge"
                                        content={<Logo />}
                                    />
                                </div>
                            </div>
                        </div>
                    </Slide.Body>
                </ShadowBackground>
            </div>
        </Slide>
    );
}

export default BusinessModel;
