import React, { FC, useEffect, useState } from 'react';
import { c } from '../../../../lib/util';
import Logo from '../../../Logo';
import ShadowBackground from '../../../ShadowBackground';
import Slide, { SlidePosition, SlideProps } from '../../Slide';
import styles from './styles.module.scss';

const Competition: FC<SlideProps> = ({ position }): JSX.Element => {
    const [ state, setState ] = useState<number>(-1);
    const [ delayedPosition, setDelayedPosition ] = useState<SlidePosition>();

    useEffect(() => {
        setTimeout(() => setDelayedPosition(position), position === 'center' ? 500 : 1);
    }, [position]);

    useEffect(() => {
        setState(-1);
        if (delayedPosition === 'center') {
            setTimeout(() => { setState((s) => s + 1) }, 500);
            setTimeout(() => { setState((s) => s + 1) }, 1000);
            setTimeout(() => { setState((s) => s + 1) }, 1500);
            setTimeout(() => { setState((s) => s + 1) }, 2000);
        }
    }, [delayedPosition]);

    return (
        <Slide position={position}>
            <div className={c([styles.competition, delayedPosition === 'center' && styles.isActive])}>
                <ShadowBackground random={9}>
                    <Slide.Body>
                        <Logo
                            className={styles.logo}
                            white
                        />
                        <Slide.Title>Concurrentie</Slide.Title>

                        <div className={styles.matrix}>
                            <div className={c([styles.logoc, styles.logoTl, state >= 0 && styles.show])}>
                                Concurrent
                            </div>
                            <div className={c([styles.logoc, styles.logoTr, state >= 3 && styles.show])}>
                                <Logo white />
                            </div>
                            <div className={c([styles.logoc, styles.logoBl, state >= 1 && styles.show])}>
                                Concurrent
                            </div>
                            <div className={c([styles.logoc, styles.logoBr, state >= 2 && styles.show])}>
                                Concurrent
                            </div>

                            <div className={styles.vertical}>
                                <div className={styles.line} />
                                <div>Betaalbaar</div>
                                <div>Duur</div>
                            </div>
                            <div className={styles.horizontal}>
                                <div className={styles.line} />
                                <div>Offline</div>
                                <div>Online</div>
                            </div>
                        </div>
                    </Slide.Body>
                </ShadowBackground>
            </div>
        </Slide>
    );
}

export default Competition;
