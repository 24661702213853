import wNumb from 'wnumb';

export const c = (classes: any[]): string => {
    return classes.filter((o) => o !== '' && o !== false && o !== undefined).join(' ');
}

export const countUp = (
    el?: HTMLSpanElement,
    target: number = 0,
    duration: number = 1500,
    units?: boolean,
): Function => {
    if (!el) return () => {};
    const delta = Math.ceil(target / 150);
    let interval: NodeJS.Timeout;
    let n: number = 0;

    return () => {
        interval = setInterval(function() {
            n += delta;

            if (n >= target) {
                clearInterval(interval);
                n = target;
            };
            
            if (!units || n < 1000) {
                el.innerHTML = number(n, 0, ',', '.');
            } else {
                el.innerHTML = number(n / 1000, 1);
                el.innerHTML += 'k';
            }
        }, duration * (delta / target));
    };
}

export const number = (
    str: any,
    decimals: number = 0,
    mark: string = '.', 
    thousand: string = ','
): string => {
    const format = wNumb({
        decimals,
        thousand,
        mark,
    });

    return format.to(decimals === 0 ? parseInt(str) : parseFloat(str));
};
