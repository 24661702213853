import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styles from './styles.module.scss';

interface CounterBlockProps {
    content?: string | JSX.Element,
    icon?: IconDefinition,
    number: number,
    title?: string,
}

const CounterBlock: FC<CounterBlockProps> = ({ content, icon, number, title }): JSX.Element => {
    return (
        <div className={styles.container}>
            {icon && (
                <div className={styles.icon}>
                    <FontAwesomeIcon icon={icon} />
                </div>
            )}
            <span className={styles.number}>
                {number}
            </span>
            <div className={styles.content}>
                {title && <h3>{title}</h3>}
                {content && <p>{content}</p>}
            </div>
        </div>
    );
}

export default CounterBlock;
